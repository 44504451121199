var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"dashboard"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"3"}},[_c('b-card',[_c('b-media',{staticClass:"cursor-pointer",attrs:{"no-body":""},on:{"click":function($event){return _vm.$router.push({
              name: 'staffs.index'
            })}}},[_c('b-media-aside',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"48","variant":"light-primary"}},[_c('i',{staticClass:"font-medium-5 ti ti-user"})])],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-bolder mb-0"},[_vm._v("Staff")]),_c('b-card-text',{staticClass:"font-small-3 mb-0"},[_vm._v(" "+_vm._s(_vm.data.staffCount)+" Staff Terdaftar ")])],1)],1)],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-card',[_c('b-media',{staticClass:"cursor-pointer",attrs:{"no-body":""},on:{"click":function($event){return _vm.$router.push({
              name: 'trainers.index'
            })}}},[_c('b-media-aside',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"48","variant":"light-info"}},[_c('i',{staticClass:"font-medium-5 ti ti-users"})])],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-bolder mb-0"},[_vm._v("Trainer")]),_c('b-card-text',{staticClass:"font-small-3 mb-0"},[_vm._v(" "+_vm._s(_vm.data.trainerCount)+" Trainer Terdaftar ")])],1)],1)],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-card',[_c('b-media',{staticClass:"cursor-pointer",attrs:{"no-body":""},on:{"click":function($event){return _vm.$router.push({
              name: 'users.index'
            })}}},[_c('b-media-aside',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"48","variant":"light-success"}},[_c('i',{staticClass:"font-medium-5 ti ti-backpack"})])],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-bolder mb-0"},[_vm._v("User")]),_c('b-card-text',{staticClass:"font-small-3 mb-0"},[_vm._v(" "+_vm._s(_vm.data.userCount)+" User Terdaftar ")])],1)],1)],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-card',[_c('b-media',{staticClass:"cursor-pointer",attrs:{"no-body":""},on:{"click":function($event){return _vm.$router.push({
              name: 'superadmin-trainings.index'
            })}}},[_c('b-media-aside',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"48","variant":"light-warning"}},[_c('i',{staticClass:"font-medium-5 ti ti-school"})])],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-bolder mb-0"},[_vm._v("Pelatihan")]),_c('b-card-text',{staticClass:"font-small-3 mb-0"},[_vm._v(" "+_vm._s(_vm.data.trainingCount)+" Pelatihan ")])],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"6"}},[(_vm.mostPopularTrainingChart.labels.length)?_c('dashboard-horizontal-bar-chart',{attrs:{"title":"Pelatihan Paling Diminati","data":_vm.mostPopularTrainingChart,"options":_vm.ChartOptions}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }